.search-box-container{
	span{
		font-size: 28px;
		margin-right: 30px;
		font-weight: 300;
	}
}

.search-box {
	font-family: 'Untitled Sans',sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 300;

	.select__control {
		transition: all 100ms;
		border-radius: 0.33rem;
		box-shadow: inset 2px 2px 2px #0000001a;
		align-content: center;
		border: solid 1px #dbdbdb;
		min-height: 30px;

		.select__value-container {
			line-height: 1;
		}

		&.select__control--is-focused {
			border-color: #dbdbdb;
			box-shadow: none;

			.select__placeholder {
				display: block;
			}
		}

		.select__indicators {
			.select__indicator-separator {
				display: none;
			}
			.select__dropdown-indicator {
				display: none;
			}
		}

		input {
			width: auto !important;;
		}
	}

	.select__menu {
		z-index: 999;
		.select__menu-list {
			.select__option {
				&.select__option--is-focused, &:hover{
					background-color: #f4f4f4;
				}
			}
		}
	}

	@media screen and (max-width: 576px) {
		.select__control {
			width: 42px;
		}
	}
}